import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, shareReplay} from 'rxjs/operators';
import {Identity} from '@frontend/models/identity';
import {Observable, ReplaySubject} from 'rxjs';
import {IdentityService as CoreIdentityService} from '@thecodeblogs/blog';

@Injectable({
    providedIn: 'root'
})
export class IdentityService extends CoreIdentityService {

    private cache$: Observable<Identity>;
    me: Identity;

    constructor(
        protected http: HttpClient,
    ) {
        super(http);
    }

    getMe(): Observable<Identity> {
        if (!this.cache$) {
            this.cache$ = this.http.get('/blog_api/me/').pipe(
                map((result) => ((result as any).results[0] as Identity)),
                shareReplay(1)
            );
        }
        return this.cache$;
    }
}
