import {Component, OnInit} from '@angular/core';
import {ResumeService} from '../resume.service';
import {Sections} from '../sections.enum';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({transform: 'translateX(0)'})),
            transition('void => *', [
                style({transform: 'translateX(-100%)'}),
                animate(600)
            ]),
            transition('* => void', [
                animate(600, style({transform: 'translateX(-100%)'}))
            ])
        ]),
        trigger(
            "blockInitial",
            [
                transition(":enter", [])
            ]
        )]
})
export class MainComponent implements OnInit {

    skills: Array<{ title: string, subskills: Array<{ name: string }> }> = [
        {
            title: 'Platforms', subskills: [
                {name: 'SystemD'},
                {name: 'Kubernetes'},
                {name: 'Docker Compose'},
                {name: 'ChatGPT'},
            ]
        },
        {
            title: 'Languages', subskills: [
                {name: 'Swift'},
                {name: 'Kotlin'},
                {name: 'Typescript/Javascript'},
                {name: 'Python'},
                {name: 'C#'},
            ]
        },
        {
            title: 'Frameworks', subskills: [
                {name: 'SwiftUI'},
                {name: 'Angular'},
                {name: 'Django'},
                {name: 'React'},
                {name: '.NET'},
            ]
        },
        {
            title: 'Databases', subskills: [
                {name: 'PostgreSQL'},
                {name: 'MariaDB'},
                {name: 'Microsoft SQL Server'},
            ]
        },
        {
            title: 'Source Control', subskills: [
                {name: 'Git'},
                {name: 'Team Foundation Server'},
                {name: 'Subversion'},
            ]
        },
        {
            title: 'Platforms', subskills: [
                {name: 'iOS'},
                {name: 'Android'},
                {name: 'Web'},
                {name: 'ChatGPT'},
            ]
        },
        {
            title: 'Environments', subskills: [
                {name: 'Debian'},
                {name: 'Ubuntu'},
                {name: 'Mac OSX'},
                {name: 'Red Hat Enterprise Linux'},
                {name: 'Windows Server'},
            ]
        },
        {
            title: 'Libraries', subskills: [
                {name: 'Alamofire'},
                {name: 'RxJS'},
                {name: 'Lodash'},
                {name: 'Django Rest Framework'},
                {name: 'Moment'},
                {name: 'ChatGPT'},
            ]
        }
    ];

    jobs: Array<{ job_title: string, dates: string, company?: string, company_description: string, responsibilities: Array<{ description: string, link?: string }> }> = [
        {
            job_title: 'Software Architect',
            dates: 'December 2021 - Current',
            company_description: '',
            responsibilities: [
                {description: 'Upgraded Ionic Apps to Latest Versions of the Ionic Framework'},
                {description: 'Worked in a full stack capacity to deliver high quality, white box labeled mobile apps to enterprise customers'},
                {description: 'Developed across the web, android and iOS platforms'},
            ]
        },
        {
            job_title: 'Founder / CEO',
            dates: 'January 2021 – Current',
            company: 'Bright Age Software LLC',
            company_description: 'Bright Age provides contracting services for programming in addition to publishing several open source tools to help developers develop',
            responsibilities: [
                {description: 'Developed Native Applications for iOS, Android, and Web to the same Backend'},
                {description: 'Worked on finding a product/market fit for several open source tools'},
                {description: 'Created TheCodeBlogs, a blogging platform geared towards developers', link: 'https://www.thecodeblogs.com'},
                {description: 'Built an open source blogging backend for django published on PyPi as django-tcb-blog', link: 'https://pypi.org/project/django-tcb-blog/'},
                {description: 'Developed an open source front end for that same backend as @thecodeblogs/blog on npm', link: 'https://www.npmjs.com/package/@thecodeblogs/blog'},
            ]
        },
        {
            job_title: 'Senior Consultant',
            dates: 'March 2021 – December 2021',
            company_description: '',
            responsibilities: [
                {description: 'Worked on business requirements to help clients develop productive software'},
                {description: 'Taken point on DevOps deployments and helping set up consistent environments'},
                {description: 'Worked with several clients to engineer custom solutions to difficult problems'},
                {description: 'Helped manage and mentor other associates to advance their careers'},
                {description: 'Acted as an Angular knowledge expert across several teams'},
            ]
        },
        {
            job_title: 'Senior Software Engineer',
            dates: 'July 2018 – March 2021',
            company_description: '',
            responsibilities: [
                {description: 'Ported an iOS client from Swift into Kotlin for the Android operating system'},
                {description: 'Implemented a system for Gradle builds to help keep track of Android releases'},
                {description: 'Directed Android releases and coordinated with iOS developers to release new versions in a coordinated fashion'},
                {description: 'Directed the architectural vision for an Angular Single Page Application (SPA)'},
                {description: 'Transitioned web assets (HTML, CSS, JS) from a home baked prototype into an Angular SPA'},
                {description: 'Migrated a large AngularJS code base into the same Angular SPA'},
                {description: 'Separated an Angular code base into separate projects and modules to increase decoupling and optimize builds'},
                {description: 'Developed customizations for Angular to seamlessly improve the user and developer experience for building components'},
                {description: 'Built a caching layer that works with the Angular service examples and does not require additional service development'},
                {description: 'Built an HttpInterceptor that handles all errors from requests and delivers them to our error aggregating service'},
                {description: 'Consolidated logic from components into models and constructors to centralize business logic rules'},
                {description: 'Trained team members on using Angular and common paradigms to port AngularJS code into Angular'},
                {description: 'Leveraged Angular to replace the request-response cycle and use native mobile HTTP libraries like Volley and AlamoFire'},
                {description: 'Integrated Angular into CI/CD build pipelines to facilitate automated deployments'},
                {description: 'Consolidated the development experience into a single docker-compose up command to ease new environment spin ups'},
            ]
        },
        {
            job_title: 'Software Engineer',
            dates: 'October 2017 – July 2018',
            company_description: '',
            responsibilities: [
            ]
        },
        {
            job_title: 'Applications Engineer',
            dates: 'March 2015 – October 2017',
            company_description: '',
            responsibilities: [
            ]
        },
        {
            job_title: 'Software Engineer',
            dates: 'April 2013 – March 2015',
            company_description: '',
            responsibilities: [
            ]
        }
    ];

    certifications: Array<{ name: string, url: string, image: string }> = [
        {
            name: 'Certified Cloud Practitioner',
            url: 'https://aws.amazon.com/certification/certified-cloud-practitioner/',
            image: '/assets/images/amazon_cloudprac.png',
        },
        {
            name: 'Cisco CCNA Certifieid',
            url: 'https://www.cisco.com/c/en/us/training-events/training-certifications/certifications/associate/ccna.html',
            image: '/assets/images/cisco_ccna.png',
        },
        {
            name: 'Microsoft Certified Professional',
            url: 'https://docs.microsoft.com/en-us/learn/certifications/roles/developer',
            image: '/assets/images/mcp_cert.png',
        },
    ];

    schools: Array<{ name: string, dates: string, description: string }> = [
        {
            name: 'Clemson University',
            dates: '2003 - 2007',
            description: 'Bachelor of Science in Microbiology',
        },
        {
            name: 'College of Charleston / Citadel',
            dates: '2012-2015',
            description: 'Masters in Computer Science',
        },
    ];

    Sections = Sections;

    currentSections: Array<Sections> = null;

    constructor(
        private resumeService: ResumeService,
    ) {
    }

    ngOnInit() {
        this.resumeService.sectionChanged.subscribe((next) => {
            this.currentSections = next;
        });
    }

    showSection(section: Sections) {
        if (this.currentSections) {
            return this.currentSections.filter((sSection) => section === sSection).length > 0;
        } else {
            return true;
        }
    }

}
