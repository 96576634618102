import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linked-in-icon',
  templateUrl: './linked-in-icon.component.html',
  styleUrls: ['./linked-in-icon.component.scss']
})
export class LinkedInIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

    click() {
        window.open('https://www.linkedin.com/in/brent-jameson-b05210ba/', "_blank");
    }
}
