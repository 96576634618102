import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-github-icon',
  templateUrl: './github-icon.component.html',
  styleUrls: ['./github-icon.component.scss']
})
export class GithubIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

    click() {
        window.open('https://www.github.com/jamesonnetworks/', "_blank");
    }

}
