import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FourOhFourComponent} from '@frontend/widgets/four-oh-four/four-oh-four.component';
import {ResumeComponent} from '@frontend/resume/resume.component';
import {EntryCreatorComponent, OutlineViewComponent, JsonRendererComponent, LandingPageComponent, SideNavigationComponent} from '@thecodeblogs/blog';
import {EntryRendererWithSidebarComponent} from '@frontend/blog/components/entry-renderer-with-sidebar/entry-renderer-with-sidebar.component';

const routes: Routes = [
    {path: 'create', component: EntryCreatorComponent},
    {path: 'create', component: OutlineViewComponent, outlet: 'left-col'},
    {path: 'create', component: JsonRendererComponent, outlet: 'right-col'},
    {path: 'blog', component: LandingPageComponent},
    {path: 'blog/:slug', component: EntryRendererWithSidebarComponent},
    {path: '', component: SideNavigationComponent, outlet: 'left-col'},
    {path: 'resume', component: ResumeComponent},
    {path: '404error', component: FourOhFourComponent, pathMatch: 'full'},
    {path: '', redirectTo: '/blog', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
