import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {FourOhFourComponent} from './widgets/four-oh-four/four-oh-four.component';
import {HttpErrorInterceptor} from './utility/HttpErrorInterceptor';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faSpinner, faRss, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {TimeAgoPipe} from './pipes/TimeAgoPipe';
import {LinkyPipe} from './pipes/LinkyPipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {AppService} from '@frontend/services/app.service';
import {AppRoutingModule} from '@frontend/app-routing.module';
import {ResumeComponent} from '@frontend/resume/resume.component';
import {SideNavComponent} from '@frontend/resume/side-nav/side-nav.component';
import {MainComponent} from '@frontend/resume/main/main.component';
import {GithubIconComponent} from '@frontend/resume/github-icon/github-icon.component';
import {LinkedInIconComponent} from '@frontend/resume/linked-in-icon/linked-in-icon.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCardModule} from '@angular/material/card';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {FileUploadModule} from 'ng2-file-upload';
import { CoreModule } from '@thecodeblogs/blog';
import { IdentityService as CoreIdentityService } from '@thecodeblogs/blog';
import { IdentityService } from '@frontend/services/identity.service';
import { EntryRendererWithSidebarComponent } from '@frontend/blog/components/entry-renderer-with-sidebar/entry-renderer-with-sidebar.component';


@NgModule({
    declarations: [
        AppComponent,
        FourOhFourComponent,
        TimeAgoPipe,
        LinkyPipe,
        ResumeComponent,
        SideNavComponent,
        MainComponent,
        GithubIconComponent,
        LinkedInIconComponent,
        EntryRendererWithSidebarComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'csrftoken',
            headerName: 'X-CSRFToken'
        }),
        NgbModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        MatCardModule,
        DragDropModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatButtonModule,
        MatInputModule,
        MatListModule,
        MatRadioModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        MatDialogModule,
        MatCheckboxModule,
        MatDividerModule,
        FileUploadModule,
        CoreModule,
    ],
    providers: [
        AppService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: CoreIdentityService,
            useClass: IdentityService
        }
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private library: FaIconLibrary
    ) {
        this.library.addIcons(faSpinner, faRss, faEnvelope, faGithub,  faLinkedin);
    }
}
