import {Component, OnInit} from '@angular/core';
import {Sections} from '../sections.enum';
import {ResumeService} from '../resume.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({transform: 'translateX(0)'})),
            transition('void => *', [
                style({transform: 'translateX(-100%)'}),
                animate(100)
            ]),
            transition('* => void', [
                animate(100, style({transform: 'translateX(20%)'}))
            ])
        ])
    ]
})
export class SideNavComponent implements OnInit {

    sections: Array<Sections> = [
        Sections.Skills,
        Sections.Certifications,
        Sections.Summary,
        Sections.Jobs,
        Sections.Education,
    ];

    constructor(
        public resumeService: ResumeService,
    ) {
    }

    ngOnInit() {
    }

    selectSection(section: Sections) {
        this.resumeService.selectSection(section);
    }

}
