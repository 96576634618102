import {EventEmitter, Injectable} from '@angular/core';
import {Sections} from './sections.enum';

@Injectable({
    providedIn: 'root'
})
export class ResumeService {

    allSections: Array<Sections> = [Sections.Skills, Sections.Certifications, Sections.Summary, Sections.Jobs, Sections.Education];

    showing: Array<Sections> = [Sections.Skills, Sections.Certifications, Sections.Summary, Sections.Jobs, Sections.Education];
    sectionChanged: EventEmitter<Array<Sections>> = new EventEmitter();

    constructor() {
    }

    removeSection(section: Sections) {
        this.showing = this.showing.filter((sSection) => {
            return section !== sSection;
        });
    }

    selectSection(section: Sections) {
        if (section === null) {
            let loop = 0;
            for (const possibleSection of this.allSections) {
                setTimeout(() => {
                    this.removeSection(possibleSection);
                    this.sectionChanged.emit(this.showing);
                }, loop * 200);
                loop++;
            }
            for (const possibleSection of this.allSections) {
                setTimeout(() => {
                    this.showing.push(possibleSection);
                    this.sectionChanged.emit(this.showing);
                }, loop * 200);
                loop++;
            }
        } else if (this.showing.length === this.allSections.length) {
            let loop = 0;
            for (const possibleSection of this.showing) {
                if (possibleSection !== section) {
                    setTimeout(() => {
                        this.removeSection(possibleSection);
                        this.sectionChanged.emit(this.showing);
                    }, loop * 200);
                    loop++;
                }
            }
        } else {
            let loop = 0;
            for (const possibleSection of this.showing) {
                setTimeout(() => {
                    this.removeSection(possibleSection);
                    this.sectionChanged.emit(this.showing);
                }, loop * 200);
                loop++;
            }
            setTimeout(() => {
                this.showing = [section];
                this.sectionChanged.emit(this.showing);
            }, 1000);
        }
    }
}
