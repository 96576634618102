import {Component, HostListener, OnDestroy, OnInit, Optional} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Entry, Interaction, InteractionService, VisitorProfile, VisitorProfileService} from '@thecodeblogs/blog';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {IdentityService} from '@frontend/services/identity.service';
import {Identity} from '@frontend/models/identity';
import {EntryService} from '@thecodeblogs/blog';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    title = 'Brent Jameson\'s Blog';
    entry: Entry;
    showRightCol: boolean;
    showLeftCol: boolean;
    shouldAllowRightCol: boolean;
    resume = false;
    identity: Identity;

    resizeSubject = new Subject<number>();

    loaded = false;

    public innerWidth: any;

    constructor(
        private router: Router,
        private entryService: EntryService,
        private identityService: IdentityService,
        private visitorProfileService: VisitorProfileService,
        private interactionService: InteractionService,
    ) {
    }

    ngOnInit() {

        const vp = this.visitorProfileService.getVisitorProfile();
        this.visitorProfileService.create(vp).subscribe((response) => {

        });

        if (document.referrer !== '') {
            const interaction = new Interaction();
            interaction.content = {'referred_by': document.referrer};
            this.interactionService.create(interaction).subscribe((response) => {

            });
        }

        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                if (e.urlAfterRedirects.includes('create')) {
                    this.showLeftCol = true;
                    this.showRightCol = true;
                    this.shouldAllowRightCol = true;
                } else if (e.urlAfterRedirects.includes('resume')) {
                    this.showLeftCol = false;
                    this.showRightCol = false;
                    this.shouldAllowRightCol = false;
                    this.resume = true;
                } else {
                    this.showLeftCol = true;
                    this.showRightCol = false;
                    this.shouldAllowRightCol = false;
                    this.resume = false;
                    this.innerWidth = window.innerWidth;
                    this.collapseLeftColIfTooNarrow();
                }
            }
        });

        this.resizeSubject.pipe(
            debounceTime(100)
        ).subscribe((width) => {
            if (!this.resume) {
                this.innerWidth = width;
                this.collapseLeftColIfTooNarrow();
            }
        });

        this.identityService.getMe().subscribe((identity) => {
            this.identity = identity;
            this.loaded = true;
        }, (err) => {
            this.identity = null;
            this.loaded = true;
        });
    }

    collapseLeftColIfTooNarrow() {
        this.showLeftCol = this.innerWidth >= 800;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.resizeSubject.next(window.innerWidth);
    }

    getCurrentYear() {
        return new Date().getFullYear();
    }

    ngOnDestroy(): void {
    }

}

